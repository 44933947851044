* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

#root {
  padding: 0px;
  margin: 0px;
}

@media print {
  html,
  body {
    font-family: "Nizar";
    height: initial !important;
    width: 297mm;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: landscape;
  margin: 0;
}
